<template>
  <div class="textmessage">
    <div class="textmessage_top">
      <span @click="fbuton">
        <i class="el-icon-arrow-left"></i> 返回&nbsp;/
      </span>
      <span>邮件通知</span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detalform:1,
      radio: "1",
      ruleForm: {
        name: "",
        KeySecret: "",
        stencils: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入阿里云AccessKeyId",
            trigger: "blur"
          },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        KeySecret: [
          {
            required: true,
            message: "请输入阿里云AccessKeySecret",
            trigger: "blur"
          },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        stencils: [
          { required: true, message: "请输入模板签名", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    fbuton() {
      this.$router.push("/setup/notifyhome");
    },
    butonalli(numindex) {
     this.detalform = numindex
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    padding: 15px;
    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }
  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 32px 56px 1px 56px;
    box-sizing: border-box;
    .spanthre {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
    }
    p {
      width: 448px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
    .textmessage_bottom_text {
      margin-bottom: 38px;
      span:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000;
      }
    }
    .iconconte {
      margin-top: 16px;
      padding: 24px;
      box-sizing: border-box;
      background: #f9fafc;
      border-radius: 3px;
      display: flex;
      .iconconte_one {
        width: 280px;
        height: 74px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;
        display: flex;
        align-items: center;
        // margin: 0 12px;
        margin-right: 12px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0;
        }
        img {
          margin-left: 12px;
          width: 49px;
          height: 53px;
        }
        .iconconte_text {
          span:first-child {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
          }
          margin-left: 16px;
          .Interface {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 12px;
            color: #0052d9;
            cursor: pointer;
          }
        }
        /deep/ .el-radio__label {
          display: none;
        }
      }
    }
    // ::v-deep .el-input__inner {
    //   -webkit-appearance: none;
    //   background-color: #fff;
    //   background-image: none;
    //   border-radius: 4px;
    //   border: 1px solid #dcdfe6;
    //   box-sizing: border-box;
    //   color: #606266;
    //   display: inline-block;
    //   height: 29px;
    //   line-height: 40px;
    //   outline: 0;
    //   padding: 0 15px;
    //   transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    //   width: 100%;
    // }
  }
  .bntout {
    margin: 60px 340px -100px;
  }
}
</style>